<template>

	<div class="main-contents">
		
		<div class="tit">프로젝트 수정하기<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/></div>

		<div class="contents-body myprj">
			<div class="my-resume">
				<!-- 프로젝트 구분 -->
				<div class="resume-box" style="border-bottom: none;">
					<div v-if="input.projectDivCd.substr(0,1) == '3'" class="tit enroll hr">공급기업 지정 프로젝트</div>
					<div v-else class="tit enroll hr">일반 구인 프로젝트</div>
				</div>

				<!-- 프로젝트 구분 -->
				<div class="resume-box">
					<div class="tit enroll">프로젝트 구분</div>
					<!-- <div class="info mt-4">
						<input type="radio" name="" id="">
						<label for="">제안중</label>
						<input type="radio" name="" id="">
						<label for="">수행예정</label>
					</div> -->
					
					<SelectComp v-if="input.projectDivCd.substr(0,1) == '3'" tagName="div" class="info mt-4 prj" type="radio" list="32:제안단계,31:수행예정" v-model="input.projectDivCd" defValue="32" @change="changeDiv()"/>
					<SelectComp v-else tagName="div" class="info mt-4 prj" type="radio" list="12:제안단계,11:수행예정" v-model="input.projectDivCd" defValue="12" @change="changeDiv()"/>
					<nbsp n="3"/>
					<p v-if="input.projectDivCd == '31' || input.projectDivCd == '11'" class="date">수주일 : </p>
					<SelectDate v-if="input.projectDivCd == '31' || input.projectDivCd == '11'" tagNm="div" type="date" class="select-box" classY="wd02" classM="wd01" classD="wd01" from="Y-2" to="Y2" title="일" rules="required" v-model="input.contractYyyymmdd"/>
				</div>

				<div class="resume-box" v-if="input.projectDivCd.substr(0,1) == '3'">
					<div class="tit enroll">공급기업 지정</div>
					<div class="tech-search-container mr-0">
						<div :class="[scoFlag == true ? 'btn open' : 'btn']" @click="openScoList()">공급기업 선택</div>
						<!-- 닫힌 상태에서는 open class를 삭제 해주세요 -->
						<div :class="[scoFlag == true ? 'container' : 'container displayNone']" style="float:none;">
							<div class="result-area sco">
								<ul>
									<!-- <li><a href="">전체</a></li> -->
									<li v-for="(sco, index) in scoList" :key="index">
										<a :class="[sco.checked ? 'active' : '']" @click="clickSco(sco, index)">{{sco.corpNm}}</a>
									</li>
								</ul>
							</div>
						</div>
						<!-- <div class="selected-area mb-0"> -->
						<div :class="[scoFlag == true ? 'selected-area mb-0' : 'selected-area mb-0 displayNone']">
							<ul>
								<!-- <li>어플리케이션개발 > 백엔드 <button class="btn-delete">삭제</button></li> -->
								<li v-for="(list, index) in scoFieldList" :key="index">{{list.corpNm}}<button class="btn-delete" @click="delSco(list, index)">삭제</button></li>
							</ul>
							<div class="aside">
								<button class="btn-reset" @click="resetScoFieldList()">초기화</button>
							</div>
						</div>
					</div>
				</div>

				<!-- 프로젝트명 -->
				<div class="resume-box">
					<div class="tit enroll">프로젝트명</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="tech">
											<InputComp type="text" classNm="enroll" placeholder="프로젝트명을 입력 해 주세요!" 
												v-model="input.projectNm" 
												title="프로젝트명"
												rules="required:true,maxLength:100"
											/>
										</td>
									</tr>
									<tr>
										<td class="tech">
											<InputComp type="text" classNm="enroll" placeholder="간략한 프로젝트 개요를 입력 해 주세요!" 
												v-model="input.projectDtlDesc" 
												title="프로젝트 개요"
												rules="maxLength:100"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- 고객사 -->
				<div class="resume-box">
					<div style="width: 50%; float: left;">
						<div class="tit enroll">고객사</div>
						<div class="info">
							<div class="Board" style="margin-bottom: 0;margin-top: 0;">
								<table class="Board_type2" style="table-layout: auto;">
									<colgroup>
										<col width="*">
									</colgroup>
									<tbody>
										<tr>
											<td class="tech">
												<InputComp type="text" classNm="task py-2 px-4" placeholder="수행처를 입력해 주세요!" 
												v-model="input.clientNm" 
												title="고객사"
												rules="maxLength:100"
												/>
												<!-- <input type="text" class="task py-2 px-4" placeholder="수행처를 입력해 주세요!"> -->
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div style="float: right; margin-right:22px">
						<div class="tit enroll">고객사  CI(기업 이미지) 등록</div>
						<div class="info">
							<div class="filebox">
								<file-select type="file" v-model="clientImgFile" :name="input.clientFileNm" accept=".jpg,.jpeg,.png"/>
								<!-- <file-select type="file" v-model="temp.excelFile" accept=".xls,.xlsx" @input="excelUpload"/> -->
								<!-- <input type="text" placeholder="파일선택"> -->
								<!-- <img class="file_search" src="/images/tec/prf/file_search.png" alt="파일찾기"/> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 근무지 -->
				<div class="resume-box">
					<div class="tit enroll">근무지</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="tech">
											<InputComp type="text" classNm="enroll" placeholder="프로젝트를 수행할 주소지를 입력 해 주세요!" 
												v-model="input.projectLocCont" 
												title="근무지"
												rules="maxLength:100"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- 담당자 -->
				<!-- 
				<div class="resume-box">
					<div class="tit enroll">담당자</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="tech">
											<input type="text" v-model="input.corpMgrMberSeq" class="task" placeholder="담당자명을 입력 해주세요!">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				-->

				<!-- 산업분야 -->
				<div class="resume-box">
					<div class="tit enroll">고객사 산업분야</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="special">
										<SelectComp @change.native="changeReset(indList)" class="special" type="select" v-model="indList.industry" :defValue="indList.industryDetail && indList.industryDetail.substr && indList.industryDetail.substr(0, 1) || '' " cdId="PRO105" title="산업분야 선택"/><nbsp/>
										<SelectComp class="special" type="select" v-model="indList.industryDetail" cdId="PRO143" :filter="indList.industry" title="상세 산업분야 선택"  :rules="[{rule: ruleRequired, args: indList.industry}]" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<!-- 프로젝트기간 -->
				<div class="resume-box">
					<div class="tit enroll">프로젝트기간</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="tech">
											<SelectDate v-model="input.projectStartYyyymmdd" :from="fromDate" :to="toDate" type="date" :disabled="input.projectStartYyyymmdd < fromDate"/><nbsp/>
											<p>부터</p>
											<SelectDate v-model="input.projectEndYyyymmdd" :from="dateLimit(fromDate, input.projectStartYyyymmdd)" :to="toDate" type="date" :disabled="input.projectEndYyyymmdd < fromDate" /><nbsp/>
											<p>까지</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- 직무분야 -->
				<list-box title="직무분야" type="duty" ref="duty" class="job myprj" v-model="dutyFieldList" v-slot="slot" :mberSeq="mberSeq">
					<div class="Board" style="margin-bottom: 0;margin-top: 0;">
						<table class="Board_type2" style="table-layout: auto;">
							<colgroup>
								<col width="*">
							</colgroup>
							<tbody>
								<tr>
									<td class="special">
										<SelectComp class="special" type="select" v-model="slot.item.dutyFilter" :defValue="slot.item.dutyDetail && slot.item.dutyDetail.substr && slot.item.dutyDetail.substr(0, 2) || '' " cdId="PRO141" title="직무분야 선택" :disabled="slot.item.dutyOld"/><nbsp/>
										<SelectComp class="special" type="select" v-model="slot.item.dutyDetail" cdId="PRO142" :filter="slot.item.dutyFilter" title="상세 직무분야 선택" :rules="[{rule: ruleRequired, args: slot.item}]" :disabled="slot.item.dutyOld"/>
										<div class="levelbox" style="width:393px">
											<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" @click="removeList(dutyFieldList, slot.index)"/>	
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</list-box>

				<!-- 개발기술 -->
				<div class="resume-box">
					<div class="tit enroll">개발기술</div>
					<div class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<td class="tech">
											<InputComp type="text" classNm="enroll" placeholder="프로젝트 개발에 필요한 기술을 입력 해 주세요!" 
												v-model="input.devTechCont" 
												title="개발기술"
												rules="maxLength:100"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<!-- 모집 자격 및 인원 -->
				<div class="resume-box recruit">
					<div class="tit enroll">모집 자격 및 인원</div>
					<!-- 1set -->
					<div v-for="(recr, index) in recrFieldList" :key="index"  class="info">
						<div class="Board" style="margin-bottom: 0;margin-top: 0;">
							<table class="Board_type2" style="table-layout: auto;">
								<colgroup>
									<col width="*">
								</colgroup>
								<tbody>
									<tr v-if="index != 0"><td><br/><br/></td></tr>
									<tr>
										<td class="project">
											<InputComp type="text" classNm="charge-task" placeholder="담당업무를 입력 해 주세요!" 
												v-model="recr.workCont" 														
												title="모집자격 및 인원의 담당업무"
												rules="required:true,maxLength:100"
												:showError="false"
											/>
											<InputComp type="text" classNm="personnel" placeholder="인원" v-model="recr.persCnt"/>
											<nbsp/>
											<SelectComp type="select" cdId="PRO106" title="기술등급" v-model="recr.techGradeCd" class="year qf"/>														
											<nbsp/>
											<SelectComp type="select" cdId="PRO107" title="경력" v-model="recr.careerTermCd" class="year qf"/>																												
											<nbsp/><img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" @click="removeList(recrFieldList, index)"/>
										</td>
									</tr>
									<tr>
										<td class="project">
											<p class="term">참여기간</p><nbsp/>
											<SelectDate title="참여시작일" v-model="recr.joinTermStartYyyymmdd" :from="dateLimit(fromDate, input.projectStartYyyymmdd)" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.joinTermStartYyyymmdd < fromDate"/>
											<p class="itv">부터</p><nbsp/> 
											<SelectDate title="참여종료일" v-model="recr.joinTermEndYyyymmdd" :from="dateLimit(fromDate, [recr.joinTermStartYyyymmdd, input.projectStartYyyymmdd])" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.joinTermEndYyyymmdd < fromDate"/>
											<p class="itv">까지</p><nbsp/>
										</td>
									</tr>
									<tr>
										<td class="project">
											<p class="term">모집기간</p><nbsp/>
											<SelectDate title="모집시작일" v-model="recr.recrTermStartYyyymmdd" :from="fromDate" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.recrTermStartYyyymmdd < fromDate"/>
											<p class="itv">부터</p><nbsp/> 
											<SelectDate title="모집종료일" v-model="recr.recrTermEndYyyymmdd" :from="dateLimit(fromDate, recr.recrTermStartYyyymmdd||input.projectStartYyyymmdd)" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.recrTermEndYyyymmdd < fromDate" :key="recr.recrTermStartYyyymmdd"/>
											<p class="itv">까지</p><nbsp/>
										</td>
									</tr>																						        
								</tbody>
							</table>
						</div>
					</div>
					<!-- 추가버튼 -->
					<div class="plus-btn"><img src="/images/tec/prf/plus_btn.png" alt="추가버튼" @click="addList(recrFieldList)"/></div>
				</div>
				<!-- 프로젝트 수행내용 -->
				<div class="resume-box last">
					<div class="tit enroll">프로젝트 수행내용 <span class="btm">※ 프로젝트 수행내용을 상세히 입력 해 주시면, 기술인재의 지원에 도움이 됩니다!</span></div>
					<InputComp type="textarea" classNm="perform" placeholder="상세한 프로젝트 내용을 입력 해 주세요!" v-model="input.projectWorkCont"/>
				</div>
				
			</div>

			<!-- 하단 버튼 -->
			<div class="select_btn">
				<div class="btn_01" @click="$router.go(-1)">취소</div>
				<div class="btn_03" style="background-color: #FF681D;" @click="saveProjectInfo()">수정하기</div>
				<div v-if="input.apprYn == 'N'" class="btn_del" @click="deleteProject()">
					삭제하기
					<span><img src="/images/prjt_x_btn.png"></span>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import listBox from "@/components/highpro/ResumeListBox.vue";
import fileSelect from "@/components/FileSelect.vue";
export default {

	components : {
		listBox,
		fileSelect
	},

	data() {
		var limitYear = 3;
		return {
			input: {
				projectSeq : null,
			},
			clientImgFile : {},
			recrFieldList : [{}], 	/* 모집자격 및 인원 (지원분야) */
			dutyFieldList : [],   /* 직무분야 */
			indList : [],	/* 산업분야 */
			fromDate : new Date().add(-limitYear, 'year').format('yyyyMMdd'),	//수정가능 년도 2년전
			toDate   : new Date().add( limitYear, 'year').format('yyyyMMdd'),	//수정가능 년도 2년후
			isReg : false,		// 수정으로 고정
			mberSeq : this.$store.state.userInfo.mberSeq,
			scoFlag : false,
			scoList : [],
			scoFieldList : [],
		};
	},
	beforeMount() {
		// console.log('beforeMount');
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.input.projectSeq = this.$route.query.projectSeq;
			this.isReg = false;
			this.getProjectInfo();
		}		
		this.getScoList();
	},

	methods: {

		getProjectInfo() {
			var input = {
				projectSeq : this.input.projectSeq
			}

			this.$.httpPost('/api/prj/getProjectInfo', input)
				.then(res => {
					// console.log(res.data);
					this.input 			= res.data.projectInfo;
					this.recrFieldList 	= res.data.projectRecrFieldList;

					// 콤마 구분자의 직무분야를 리스트로 변환
					if(this.input.dutyFieldCont) {
						var tempArr = this.input.dutyFieldCont.split(',');
						// console.log("tempArr : " + tempArr); 
						this.dutyFieldList = [];					
						for(var i in tempArr) {
							var test = {};
							this.dutyFieldList.push(test);
							this.dutyFieldList[i].dutyDetail = tempArr[i];
							this.dutyFieldList[i].dutyOld = true;
						}
					}

					if(this.input.choiceCorpList) {
						this.scoFlag = true;
						var tempList = this.input.choiceCorpList.split(',');
						for(var c of tempList) {
							for(var a=0; a<this.scoList.length; a++) {
								if(this.scoList[a].corpSeq == c) {
									this.scoList[a].checked = true;
									this.scoList[a].index = a;
									this.scoFieldList.push(this.scoList[a]);
									break;
								}
							}
						}
					}

					if(res.data.projectInfo.projectIndtypeClassCd) {
						this.indList.industryDetail = res.data.projectInfo.projectIndtypeClassCd;
					}
					//--

					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},
		addList(list) {
			var temp = {};
			list.push(temp);
			this.$forceUpdate();
		},

		removeList(list, index) {
			list.splice(index, 1);
			if(list.length == 0) {
				this.addList(list);
			}
			this.$forceUpdate();
		},

		saveProjectInfo() {

			// if(!this.validCheck()) {
			// 	return;
			// }

			// 직무분야 리스트 -> 콤마 구분자로 변환
			if(this.dutyFieldList[0].dutyDetail != undefined) {
				var dutyFieldCont = '';

				for(var i=0; i<this.dutyFieldList.length; i++) {
					if(this.dutyFieldList[i].dutyDetail instanceof Error) {
						continue;
					}
					dutyFieldCont += this.dutyFieldList[i].dutyDetail;				
					if(i < this.dutyFieldList.length-1) {
						dutyFieldCont += ',';
					}
				}
				this.input.dutyFieldCont = dutyFieldCont;
			}

			// 지정공급기업목록 변환
			if(this.scoFieldList.length > 0) {
				var scoFieldCont = '';
				for(var j = 0; j<this.scoFieldList.length; j++) {
					scoFieldCont += this.scoFieldList[j].corpSeq;
					if(j < this.scoFieldList.length-1) {
						scoFieldCont += ',';
					}
				}

				this.input.choiceCorpList = scoFieldCont;
			}
			
			this.input.recrFieldList = this.recrFieldList;
			this.input.industryDetail = this.indList.industryDetail;

			// 산업분야 체크
			if(this.input.industryDetail == "" || this.indList.industryDetail == undefined) {
				alert("선택되지 않은 산업분야가 있습니다.");
				return;
			}

			// 직무분야 체크
			for(var val of this.dutyFieldList) {
				if(val.dutyFilter == undefined){
					alert("선택되지 않은 직무분야가 있습니다.");
					return;
				}
				// console.log(val.dutyDetail == undefined);
				if(val.dutyDetail == undefined || val.dutyDetail == "" || val.dutyDetail instanceof Error) {
					alert("선택되지 않은 상세 직무분야가 있습니다.");
					return;
				}
			}
			//--

			var files = [];

			if(this.clientImgFile instanceof File){
				//첨부파일이 있을 경우 첨부파일을 추가한다.
				files.push(this.clientImgFile);
                this.input.clientFileNm = this.clientImgFile.name;
				this.input.clientFileYn = 'Y';
			}

			this.$.fileUpload('IMG', files,'/api/prj/adm/updateProjectInfo', this.input)
				.then(() => {
					alert('수정 되었습니다.');
					this.$router.go(-1);
				})
				.catch(this.$.httpErrorCommon);
		},

		dateLimit(defVal, checks){
			// console.log('dateLimit', defVal, checks);
			if(!Array.isArray(checks)) checks = [checks];
			for(var i in checks){
				if(typeof checks[i] == 'string')
					return checks[i]
			}
			return defVal;
		},

		ruleRequired(v, item, msg){
			if(!v){
				// console.log('', 'ruleRequired', v, item, msg);
				for(var key in item){
					if(key.indexOf('_') != 0 && item[key]){
						if(item[key] instanceof Error){
							// console.log('', 'ruleRequired', key, item[key].message);
						} else {
							return msg || '항목 입력 시 필수 입력입니다.'
						}
					}
				}
			}
			return true;
		},

		changeReset(item) {				
			this.indList = [];					
			this.indList.industry = item.industry;
		} ,

		openScoList() {
			if(!this.scoFlag) {
				this.scoFlag = true;
			} else {
				this.scoFlag = false;
			}
		},

		getScoList() {
			this.$.httpPost('/api/prj/adm/getScoList')
                .then(res => {
                    this.scoList = res.data;
                }).catch(this.$.httpErrorCommon);
		},

		clickSco(sco, index) {
			if(sco.checked == undefined || sco.checked == false) {
				sco.checked = true;
				sco.index = index;
				this.scoFieldList.push(sco);		
			} else {
				sco.checked = false;
				for(var i=0; i<this.scoFieldList.length; i++) {
					if(this.scoFieldList[i].corpSeq == sco.corpSeq) {
						this.scoFieldList.splice(i,1);
					}
				}
			}			
		},

		delSco(list, index) {
			// console.log(this.scoFieldList);
			this.scoList[list.index].checked=false;
			this.scoFieldList.splice(index,1);
		},

		resetScoFieldList() {
			// console.log(this.scoFieldList);
			for(var list of this.scoFieldList){
				this.scoList[list.index].checked = false;
			}
			this.scoFieldList.splice(0,this.scoFieldList.length);
		},

		changeDiv() {
			// console.log(this.input);
			this.input.contractYyyymmdd = '';
		},

		deleteProject() {
			this.input.recrFieldList = this.recrFieldList;

			this.$.popup('/adm/prj/PRJ910P05', {projectNm : this.input.projectNm})
				.then(res => {
					if(res) {
						this.input.recrFieldList = this.recrFieldList;

						this.$.httpPost('/api/prj/adm/deleteProject', this.input)
							.then(() => {
								alert("삭제되었습니다.");
								var params = {
									caller : {
										name: this.$route.name,
										params: this.input,
									}
								}
								this.$router.push({name: 'PRJ910M01', params });
							})
							.catch(err => {
								alert(err.response.data.error_description);
							});
						
					}
				});

		}

	},
};
</script>
